.card {
  min-height: 340px;
  min-width: 190px;
  max-width: 280px;
  border-radius: 6px;
  justify-self: center;
}

.cardHeader {
  min-height: 85px;
  width: -webkit-fill-available;
  border-radius: 6px 6px 0 0;
}

.cardBody {
  border-radius: 0 0 6px 6px;
  padding: 60px 15px 10px 15px;
  width: -webkit-fill-available;
}

.icon {
  border-radius: 50%;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}

.imageDiv {
  border: 8px;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  overflow: hidden;
  margin-top: 32px;
}

.imageVector {
  right: 5px;
  top: -10px;
  width: 85px;
}

@media only screen and (max-width: 960px) {
  .reportsGrid {
    grid-template-columns: 1fr;
  }
}
